$(document).ready(function() {
    /* Select active menu */
    var adress = window.location.pathname;
    first =adress.split('/');
    first = first[1];
    if(adress == '/') {
        $( ".test" ).first().addClass('activeMenu');
    } else {
        $( ".navbar-nav > li" ).each(function( index ) {
            if($(this).html().indexOf(adress) > 0) {
                $( ".navbar-nav .activeMenu" ).removeClass('activeMenu');
                $(this).addClass('activeMenu');
                //if($(window).width() > 640)
                //movePagesMenu();
                $('.activeMenu > a').click();
                if ($(this).hasClass('dropdown-submenu')) {
                    $(this).find('ul li').each(function( index ) {
                        if($(this).html().indexOf(adress+'"') > 0) {
                            $(this).addClass('activeMenu');
                        }
                    });
                }
                first = null;
                return;
            } else if($(this).html().indexOf(first) > 0) {
                $(this).addClass('activeMenu');
            }
        });
    }
    
    $('#search-button, #close-search').click(function(event) {
        event.preventDefault();
        menuActivatePart($(this).data("menu"));
        $('#search-input').focus();
    });

    $('.menu .menu-category').click(function(event) {
        event.preventDefault();
        menuActivatePart($(this).data("menu"));
    });
});

    function moveMenu() {
        $('.body').toggleClass('body--calendar-open');
        if($('.body').hasClass('body--menu-open'))
            $('.body').toggleClass('body--menu-open');
        //showMapLegend();
    }

    function movePagesMenu() {
        $('.body').toggleClass('body--menu-open');

        if($('.body').hasClass('body--calendar-open')){
            $('.body').toggleClass('body--calendar-open');
        }

    }

    function openMenu() {
        $('#menu-option').toggleClass('menu-top-line--mobile-hidden');
    }

    

    function menuActivatePart (classActive) {
        classMenu = 'menu--active-' + classActive;
        if ($('#menu').hasClass(classMenu)) {
            $('#menu').removeClass(classMenu);
        } else {
            $('#menu').removeClass(
                ' menu--active-group'
                + ' menu--active-individual'
                + ' menu--active-motor'
                + ' menu--active-object'
                + ' menu--active-search')
                .addClass(classMenu);
            if ($( window ).width() < 768) {
                $('#menu-category-' + classActive)
                    .after($('#menu-sports-' + classActive));
            }
        }
    }

$(document).ready(function () {
    $('#scrollUp').click(function (event) {
        event.preventDefault();
        scrollUp();
    });
    $('#scrollDown').click(function (event) {
        event.preventDefault();
        scrollDown();
    });
    $('#calendar-container').scroll(function() {
        position = $('#calendar-daily-events').position().top;
        height = $('#calendar-daily-events').outerHeight();
        containerHeight = $('#calendar-container').height();
        if (position + 5 > $('#calendar').height()) {
            $('#scrollUp').addClass('scroll-button--inactive');
        } else if (position + height - 5 < containerHeight) {
            $('#scrollDown').addClass('scroll-button--inactive');
        } else {
            $('.scroll-button').removeClass('scroll-button--inactive');
        } 
    });
    $(window).scroll(function() {
        position = $(window).scrollTop();
        height = $('#menu').outerHeight();
        //alert(height);
        if (position > height) {
            if (!$('#menu').hasClass('menu--fixed')) {
                $('#menu').removeClass('menu--active-search')
                    .addClass('menu--fixed');
                $('.content').attr('style', 'margin-top:' + height + 'px;');
                $('#button-fixed-container').removeClass('button-fixed-container--down');
            }
        } else  {
            $('#menu').removeClass('menu--fixed');
            $('.content').attr('style', 'margin-top:0px;');
            $('#button-fixed-container').addClass('button-fixed-container--down');
        }
    });
});

function scrollUp () {
    position = $('#calendar').offset().top;
    position = - position  - $('#calendar').height() / 2;
    $("#calendar-container").animate({
        scrollTop: position
    }, 400, 'linear');
}

function scrollDown () {
    position = $('#calendar').offset().top;
    position = - position + $('#calendar').height() / 2;
    $("#calendar-container").animate({
        scrollTop: position
    }, 400, 'linear');
}

function checkContentOfInput (element) {
    if ('' == $(element).val()) {
        $(element).next().attr('style', '');
    } else {
        $(element).next().attr('style', 'opacity: 0');
    }
}

function showHiddenInfo (selector) {
    $(selector).toggleClass('form__hide-description--open');
}

function activateCheckbox (selector) {
    $(selector).click();
}

function scrollPageToTop (event) {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "slow");
}


// wcag function START
function normalStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet.css');
    $.cookie('style', 'normal', {
        expires : 365, 
        path    : '/', 
        domain  : domain
    });
}

function wcagStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-wcag.css');
    $.cookie('style', 'wcag', {
        expires : 365, 
        path    : '/', 
        domain  : domain
    });
}

function smallFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-small';
    $.cookie('font', 'small', {
        expires : 365, 
        path    : '/', 
        domain  : domain
    });
}

function mediumFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-medium';
    $.cookie('font', 'medium', {
        expires : 365, 
        path    : '/', 
        domain  : domain
    });
}

function largeFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-large';
    $.cookie('font', 'large', {
        expires : 365, 
        path    : '/', 
        domain  : domain
    });
}

$(document).ready(function () {
    style = $.cookie('style');
    font = $.cookie('font');
    if (style === "wcag") {
        wcagStyle(document.createEvent('Event'));
    } 
    if (font === "medium") {
        mediumFont(document.createEvent('Event'));
    } 
    if (font === "large") {
        largeFont(document.createEvent('Event'));
    } 
});
// wcag function END